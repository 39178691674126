import * as React from 'react';
import styles from './booking-hours-input.module.scss';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import PulseInput from 'components/pulse-input/pulse-input';
import { debounce, inRange } from 'lodash';
import { addHours } from 'date-fns';

const MIN_BOOKING_HOURS = 0.0;
const MAX_BOOKING_HOURS = 23.95;
const STEP = '0.05';

const BookingHoursInput: React.FC<{
  isSingleDay: boolean;
}> = ({ isSingleDay = false }) => {
  const { values, errors, setFieldValue } = useFormikContext<Record<string, any>>();
  const { startDate, hoursPerDay } = values;
  const inputHoursRef = React.useRef<HTMLInputElement>();
  const invalid = errors?.hoursPerDay;
  const hours = Number(hoursPerDay).toFixed(2);

  const getRangeHours = (newHoursInput: string) => {
    const newHours = parseFloat(newHoursInput);
    let newValue = newHours?.toFixed(2);
    const inHoursRange = inRange(newHours, MIN_BOOKING_HOURS, MAX_BOOKING_HOURS);
    // limit of over range
    if (!inHoursRange) {
      if (newHours < MIN_BOOKING_HOURS) {
        newValue = `${MIN_BOOKING_HOURS.toFixed(2)}`;
      }
      if (newHours > MAX_BOOKING_HOURS) {
        newValue = `${MAX_BOOKING_HOURS.toFixed(2)}`;
      }
    }
    return newValue;
  };

  const handleSetBookingHours = newHoursDay => {
    setFieldValue('hoursPerDay', newHoursDay);
  };

  const handleChangeInput = React.useCallback(
    debounce((newHoursDay, isUpdateState = false) => {
      const newValue = Number(newHoursDay).toFixed(2);
      if (isUpdateState) {
        handleSetBookingHours(newValue);
      }
      if (inputHoursRef.current) {
        inputHoursRef.current.value = newValue;
      }
    }, 600),
    [],
  );

  const handleClickField = () => {
    if (inputHoursRef.current) {
      inputHoursRef.current.focus();
      inputHoursRef.current.select();
    }
  };

  const updateTheEndTime = () => {
    if (isSingleDay) {
      const newEndDate = addHours(startDate, hoursPerDay);
      setFieldValue('endDate', newEndDate);
    }
  };

  React.useEffect(() => {
    handleChangeInput(hoursPerDay);
    if (isSingleDay) {
      updateTheEndTime();
    }
    return () => {
      handleChangeInput.cancel();
    };
  }, [hoursPerDay]);

  return (
    <div className={styles['booking-hours-input']} onClick={handleClickField}>
      <p className={styles['booking-hours-input__label']}>HRS/DAY</p>
      <PulseInput
        label=""
        classes={{
          root: clsx(
            styles['booking-hours-input__inputBase'],
            invalid && styles['booking-hours-input__inputBase--invalid'],
          ),
        }}
        InputBaseProps={{
          onKeyPress: e => {
            if (!inputHoursRef.current) {
              return;
            }
            if (e.key == 'Enter') {
              const newHoursDay = getRangeHours(inputHoursRef.current.value);
              handleChangeInput(newHoursDay, true);
            }
          },
          fullWidth: false,
          defaultValue: hours,
          type: 'number',
          placeholder: 'HRS/DAY',
          inputProps: {
            ref: inputHoursRef,
            min: MIN_BOOKING_HOURS,
            max: MAX_BOOKING_HOURS,
            maxlength: 4,
            step: STEP,
          },
        }}
      />
    </div>
  );
};
export default React.memo(BookingHoursInput);
